const CSP_STORAGE_ITEM_PREFIX = 'CSP_STORAGE_ITEM_';

const generateStorageKey = (key: string): string => {
    return `${CSP_STORAGE_ITEM_PREFIX}${key}`;
}

export const putData = (key: string, value: string): void => {
    window.localStorage.setItem(generateStorageKey(key), value);
}

export const getData = (key: string): string | null => {
    return window.localStorage.getItem(generateStorageKey(key));
}

export const removeData = (key: string): void => {
    window.localStorage.removeItem(generateStorageKey(key));
}

export const putSessionData = (key: string, value: string): void => {
    window.sessionStorage.setItem(generateStorageKey(key), value);
}

export const getSessionData = (key: string): string | null => {
    return window.sessionStorage.getItem(generateStorageKey(key));
}

export const removeSessionData = (key: string): void => {
    window.sessionStorage.removeItem(generateStorageKey(key));
}