import { makeStyles, typographyStyles, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px 0 12px',
    justifyContent: 'space-between',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    height: '24px',
    width: '2px',
    backgroundColor: tokens.colorNeutralBackgroundInverted,
    margin: '0 4px',
  },
  marginLeft8: {
    marginLeft: '8px',
  },
  marginLeft24: {
    marginLeft: '24px',
  },
  marginRight16: {
    marginRight: '16px',
  },
  logoFont: {
      ...typographyStyles.body1,
      fontWeight: tokens.fontWeightSemibold,
      marginLeft: '8px',
      cursor: 'pointer',
    },
});