import React from 'react';
import { Image } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import LogoImg from '/logo.svg';

const Logo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Image src={LogoImg} alt={t('Logo')} block />
      </div>
      <span className={classes.logoFont}>{t('MicrosoftAds')}</span>
    </div>
  );
};

export default Logo;