import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import ErrorBoundaryWrapper from '@components/ErrorBoundary';
import { AppContext, IApp } from '@contexts/appContext';
import ToastProvider from '@components/ToastProvider';
import { logger } from '@services/logger';
import { msalConfig } from './authConfig';
import { Provider } from 'react-redux';
import { store } from '@store/store';
import { v4 as uuidv4 } from 'uuid';
import { getSessionData, putSessionData } from '@utils/dataStorage';
import { APP_SESSION_ID_KEY } from '@constants/common';
import App from './app.tsx';
import './index.css';
import './i18n.ts';

const lastUsedSessionId = getSessionData(APP_SESSION_ID_KEY);
const currentSessionId = lastUsedSessionId || uuidv4();
if (lastUsedSessionId !== currentSessionId) {
  putSessionData(APP_SESSION_ID_KEY, currentSessionId);
}

const appContextValue: IApp = {
  sessionId: currentSessionId,
};

logger.updateSessionId(appContextValue.sessionId);

window.onerror = (message, source, lineno, colno, error) => {
  const errorMessage = `window.onerror: ${message}, currentUrl: ${source}, lineNumber: ${lineno}, column: ${colno}, error: ${error}`;
  logger.error(errorMessage);
}

window.onclick = (e: MouseEvent) => {
  if (e.target instanceof HTMLAnchorElement) {
    logger.userClickAction(e.target.href);
  }
}

const initialize = async () => {
  initializeIcons();
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  // Use Fluent Provider as the root component
  const fluentProviderStyle: React.CSSProperties = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }

  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <ErrorBoundaryWrapper>
        <FluentProvider theme={webLightTheme} style={fluentProviderStyle}>
          <ToastProvider>
            <Provider store={store}>
              <MsalProvider instance={msalInstance}>
                <AppContext.Provider value={appContextValue}>
                  <App />
                </AppContext.Provider>
              </MsalProvider>
            </Provider>
          </ToastProvider>
        </FluentProvider>
      </ErrorBoundaryWrapper>
    </StrictMode>,
  )
};

const urlParams = new URLSearchParams(window.location.search);
const isNewCSP = urlParams.get('isNewCSP');

logger.info('CSP Starts');
if (isNewCSP === 'false') {
  logger.info('CSP redirects to Salesforces');
  window.location.href = 'https://help.xandr.com/s/';
} else {
  logger.info('CSP stays in UCM CSP');
  initialize();
}