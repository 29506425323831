import { createSlice } from '@reduxjs/toolkit';
import { ESeverity, EStep, EDetailFormKey, EContactFormKey, TContactInfo } from '@constants/contact';

const initialState: TContactInfo = {
  // Controller
  step: EStep.TopAndCat,
  // TopAndCat
  topic: '',
  category: '',
  // CaseDetail
  checkOutageCriteria: false,
  [EDetailFormKey.ssp]: '',
  [EDetailFormKey.cpm]: '',
  [EDetailFormKey.dealId]: '',
  [EDetailFormKey.domain]: '',
  [EDetailFormKey.subject]: '',
  [EDetailFormKey.dealName]: '',
  [EDetailFormKey.timezone]: '',
  [EDetailFormKey.currency]: '',
  [EDetailFormKey.severity]: ESeverity.level3,
  [EDetailFormKey.attachments]: [],
  [EDetailFormKey.description]: '',
  [EDetailFormKey.dealDateEnd]: '',
  [EDetailFormKey.dealDateStart]: '',
  [EDetailFormKey.buyerMemberId]: '',
  [EDetailFormKey.relatedMembers]: [],
  [EDetailFormKey.dealProposalId]: '',
  [EDetailFormKey.impressionCount]: '',
  [EDetailFormKey.dealProposalName]: '',
  [EDetailFormKey.appealJustification]: '',
  // ContactDetail
  [EContactFormKey.ccEmails]: '',
  [EContactFormKey.contactName]: '',
  [EContactFormKey.companyName]: '',
  [EContactFormKey.contactEmail]: '',
  [EContactFormKey.submissionCountry]: '',
}

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetContactInfo: () => initialState,
    setStep: (state: TContactInfo, action: { payload: EStep }) => {
      state.step = action.payload;
    },
    setContactField: (state: TContactInfo, action: { payload: Partial<TContactInfo> }) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setStep, setContactField, resetContactInfo } = contactSlice.actions;

export default contactSlice.reducer;
