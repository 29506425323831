import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mergeClasses, Link, Subtitle2 } from '@fluentui/react-components'
import UserAvatar from '@components/UserAvatar';
import { useTranslation } from 'react-i18next';
import { RootState } from '@store/store';
import Logo from '@components/Logo';
import { useStyles } from './style';
import { navigationTid } from "@constants/testId.ts";

interface IProps {
    className?: string;
}

// TODO: yren add plugin to transfer png to webp
const Header: React.FC<IProps> = (props) => {
    const user = useSelector((state: RootState) => state.user);
    // TODO: yren add ts here, the key need to be inclueded in the common.json
    const { t } = useTranslation('common');
    const classes = useStyles();
    const { userId } = user;

    return (
        <header className={mergeClasses(classes.header, props.className)}>
            <div className={classes.flexRow}>
                <NavLink to="/" end className={classes.flexRow}>
                    <Logo />
                    <span className={classes.divider} />
                    <Subtitle2 className={classes.logoFont}>{t('Support')}</Subtitle2>
                </NavLink>
                <NavLink className={classes.marginLeft24} to="/case-list">
                    <Link>{t('MyCases')}</Link>
                </NavLink>
            </div>
            <div className={classes.flexRow}>
                <NavLink className={classes.marginRight16} to="/contact-support">
                    <Link data-testid={navigationTid.contactSupport}>{t('ContactSupport')}</Link>
                </NavLink>
                {userId ? (
                    <UserAvatar/>
                ) : (
                    <NavLink to="/sign-in">
                        <Link>{t('SignIn')}</Link>
                    </NavLink>
                )}
            </div>
        </header>
    );
};

export default Header;