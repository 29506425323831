import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { csrfToken, fetchCsrfToken } from './security';
import { API_BASE_URL } from '@constants/env';
import { logger } from './logger';

const api_name_error_placeholder = 'error_null_url';

interface IAxiosConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
  metadata: {
    disableLog?: boolean,
    requestStartTime: Date,
    requestEndTime?: Date,
  }
}

const apiLogger = (res: AxiosResponse | AxiosError, type: 'Succeeded' | 'Failed' | 'Expected') => {
  const metaData = (res.config as IAxiosConfig).metadata;
  if (!metaData?.disableLog) {
    const apiName = res.config?.url || api_name_error_placeholder;
    if (type === 'Succeeded') {
      metaData.requestEndTime = new Date();
      logger.perfInfo(apiName, metaData.requestStartTime, metaData.requestEndTime);
    }
    if (type === 'Failed') {
      logger.error(`Request:${apiName}, Error:${JSON.stringify(res)}`);
    }
    logger.info(`Request:${apiName}, Status:${res.status || '-'}, Result:${type}`);
  }
};

const handleCsrfMismatch = async (error: AxiosError) => {
  const originalRequest = error.config as IAxiosConfig;
  if (!originalRequest?._retry) {
    originalRequest._retry = true;
    try {
      await fetchCsrfToken();
      originalRequest.headers['X-CSP-XSRF-TOKEN'] = csrfToken;
      return apiClient(originalRequest);
    } catch (csrfError) {
      return Promise.reject(csrfError);
    }
  }
  return Promise.reject(error);
}

const handleResponseError = async (error: AxiosError) => {
  const status = error.response?.status;
  const data = error.response?.data;

  // If the error is a CSRF token mismatch error
  if (status === 400 && data && typeof data === 'object' && 'error' in data && data.error === 'CSRF token mismatch.') {
    return await handleCsrfMismatch(error);
  }

  // If user has no permission to access the resource
  if (status === 403) {
    // Expected error, log it and return a rejected promise
    apiLogger(error, 'Expected');
    return Promise.reject(new Error('No permission'));
  }

  // Log the error without above scenarios
  apiLogger(error, 'Failed');
  return Promise.reject(error);
}

const apiClient = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    post: {
      'Content-Type': 'application/json',
    }
  }
});

apiClient.interceptors.request.use(
  (config) => {
    const metaData = (config as IAxiosConfig).metadata;
    (config as IAxiosConfig).metadata = { ...metaData, ...{ requestStartTime: new Date() } }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    apiLogger(response, 'Succeeded');
    return response.data;
  },
  handleResponseError
);

const fetchData = (url: string, config: AxiosRequestConfig = {}): Promise<unknown> => {
  return apiClient.get(url, config);
};

const postData = (url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<unknown> => {
  const tempConfig = {
    ...config,
    headers: {
      'X-CSP-XSRF-TOKEN': csrfToken,
      ...config.headers,
    }
  };
  return apiClient.post(url, data, tempConfig);
};

const postDataWithoutLog = (url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<unknown> => postData(url, data, {
  ...config,
  metadata: {
    disableLog: true,
  }
} as IAxiosConfig);

export { fetchData, postData, postDataWithoutLog };
